<template>
  <div class="p-4 bg-white rounded-lg custom-content-height">
    <div class="mb-7 mx-4 mt-6 d-flex align-center justify-content-between">
      <datatable-detail
        class="guide-detail"
        :table_properties="tableProperties"
      />

      <!--      <div class="col-6 justify-content-end px-4">-->
      <!--        <button-->
      <!--          class="btn btn&#45;&#45;export-filter float-end"-->
      <!--          @click.prevent="submitForm"-->
      <!--        >-->
      <!--          <span class="svg-icon">-->
      <!--            <v-icon size="18">mdi-cloud-upload</v-icon>-->
      <!--          </span>-->
      <!--          Update-->
      <!--        </button>-->
      <!--      </div>-->
    </div>

    <v-container class="d-flex flex-column flex-fill ml-2 mr-4 px-10">
      <v-row v-if="serverData">
        <v-col cols="12" xl="8">
          <FieldContainer label="Trade License / Business License name">
            <v-file-input
              v-model="formData.license"
              class="rounded-lg h-45px"
              clearable
              outlined
              dense
              prepend-icon=""
              append-icon="mdi-file"
            />
          </FieldContainer>

          <FieldContainer label="Please provide your company website">
            <v-text-field
              v-model="formData.website"
              class="rounded-lg h-45px"
              clearable
              outlined
              dense
            />
          </FieldContainer>

          <FieldContainer label="First name">
            <v-text-field
              v-model="formData.name"
              class="rounded-lg h-45px"
              clearable
              outlined
              dense
            />
          </FieldContainer>

          <FieldContainer label="Last name">
            <v-text-field
              v-model="formData.surname"
              class="rounded-lg h-45px"
              clearable
              outlined
              dense
            />
          </FieldContainer>

          <FieldContainer
            label="Contact for Operations and General Communications"
          >
            <v-text-field
              v-model="formData.contact_email"
              class="rounded-lg h-45px"
              clearable
              outlined
              dense
            />
          </FieldContainer>

          <FieldContainer label="Contact for Finance Communication">
            <v-text-field
              v-model="formData.fincance_email"
              class="rounded-lg h-45px"
              clearable
              outlined
              dense
            />
          </FieldContainer>

          <FieldContainer label="Service regions">
            <v-autocomplete
              v-model="formData.regions"
              class="rounded-lg h-45px"
              :items="serverData?.regions?.values"
              item-text="text"
              item-value="index"
              multiple
              small-chips
              deletable-chips
              dense
              outlined
              clearable
            />
          </FieldContainer>

          <FieldContainer label="Shipping types">
            <v-autocomplete
              v-model="formData.shipping_types"
              class="rounded-lg h-45px"
              :items="serverData?.shippingTypes?.values"
              item-text="text"
              item-value="index"
              multiple
              small-chips
              deletable-chips
              dense
              outlined
              clearable
            />
          </FieldContainer>

          <FieldContainer label="Service types">
            <v-autocomplete
              v-model="formData.serviceTypes"
              class="rounded-lg h-45px"
              :items="serverData?.customerServices?.values"
              item-text="text"
              item-value="index"
              multiple
              small-chips
              deletable-chips
              dense
              outlined
              clearable
            />
          </FieldContainer>

          <FieldContainer
            label="Do you have products that are considered Dangerous or Hazardous?"
          >
            <v-switch class="mt-0" v-model="formData.is_dg" inset dense />
          </FieldContainer>

          <FieldContainer label="Temperature Controlled">
            <v-switch
              class="mt-0"
              v-model="formData.is_temperature_controlled"
              inset
              dense
            />
          </FieldContainer>

          <FieldContainer label="Kitting/Bundling">
            <v-switch class="mt-0" v-model="formData.is_bundle" inset dense />
          </FieldContainer>

          <FieldContainer label="Quality Checking">
            <v-switch class="mt-0" v-model="formData.is_qc" inset dense />
          </FieldContainer>

          <FieldContainer label="Dimension Capturing" :divider="false">
            <v-switch class="mt-0" v-model="formData.is_dc" inset dense />
          </FieldContainer>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12" xl="8" class="pr-0">
          <v-row class="d-flex justify-content-end">
            <v-col cols="auto">
              <button
                @click="submitForm"
                type="submit"
                class="btn btn-info px-5 py-3 poppins-ls"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import DatatableDetail from "@/own/components/datatable/DatatableDetail.vue";
import FieldContainer from "@/own/components/forms/FieldContainer.vue";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import introJs from "intro.js";
import { SET_PROF_COMPLNESS_SHOWN } from "@/core/services/store/auth.module";
// import Dropzone from "dropzone";

export default {
  name: "CompleteProfile",
  components: { DatatableDetail, FieldContainer },
  mixins: [validationMixin],
  // validations() {
  //   return {
  //     formData: {
  //       license: { required },
  //       name: { required },
  //       surname: { required },
  //       contact_email: { required, email },
  //       fincance_email: { required, email },
  //       // is_service_required: { required },
  //       regions: { required },
  //     },
  //   };
  // },
  data: () => ({
    serverData: {},
    formData: {
      license: null,
      // license_url: null,
      // email: null,
      // phone_number: null,
      website: "",
      name: null,
      surname: null,
      contact_email: null,
      fincance_email: null,
      // is_service_required: null,
      regions: [],
      shipping_types: [],
      serviceTypes: [],
      is_temperature_controlled: false,
      is_dg: false,
      is_bundle: false,
      is_qc: false,
      is_dc: false,
      company: null,
    },
    // fileTitle: "SSS",
    // img: "some_image_file_name.jpg",
  }),

  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/api/v2/complete-profile/get", {})
        .then(({ data }) => {
          // console.log("loggg", data);
          this.serverData = data;
          this.formData.company = data.company;
          this.formData.contact_email = data.contact_email;
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .finally(() => {
          this.$store.commit(SET_PROF_COMPLNESS_SHOWN, true);

          if (
            !this.$store.getters.getGuidanceFinishedPages.includes(
              "integration"
            )
          ) {
            const element = document.querySelector(
              ".w-100.order_management-guide-icon > svg"
            );

            introJs()
              .setOptions({
                steps: [
                  {
                    title: "Effortless Integration",
                    element,
                    intro:
                      '<p class="font-size-lg">Easily connect your online store to the platform in just a few steps.</p> <br/> <img src="/img/s4.png" width="270" alt="Integration">',
                    // intro:
                    //   "Easily connect your online store to the platform in just a few steps.",
                  },
                ],
                highlightClass: "intro-no-border",
                tooltipClass: "tooltip_class",
                showBullets: false,
                skipLabel: "",
                doneLabel: "OK",
                hidePrev: true,
                tooltipPosition: "right",
              })
              .start();
          }
        });
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        // console.log("field and typeof", item[0], typeof item[1]);
        if (
          (item[1] && item[0] != "image" && item[0] != "photo") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], item[1], item[1].name);
            // data.append(item[0], JSON.stringify(item[1]));
          } else if (
            typeof item[1] === "boolean" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], item[1] === true ? "1" : "0");
            // data.append(item[0], JSON.stringify(item[1]));
          } else {
            data.append(item[0], item[1]);
          }
        }
        // else if (item[1] && (item[0] == "image" || item[0] == "photo")) {
        //   // console.log(item[1], item[0], item[1][0]);
        //   data.append(item[0], item[1][0]);
        // }
      });
      return data;
    },
    submitForm() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = this.convertToFormData();
      // console.log("convertToFormData", data);
      ApiService.post("/api/v2/complete-profile/complete", data)
        .then(({ data }) => {
          if (data.success) {
            Swal.fire(
              "Success!",
              "Data has been saved successfully.",
              "success"
            );
          }

          const currentUser = this.store?.getters?.currentUser;
          this.$router.push({ name: currentUser.permissions[0] });
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // introJs()
      //   .setOptions({
      //     steps: [
      //       {
      //         title: "Welcome",
      //         intro: "Hello World! 👋",
      //         buttonClass: "iq-btn",
      //       },
      //       // {
      //       //   intro:
      //       //     '<img src="https://i.giphy.com/media/ujUdrdpX7Ok5W/giphy.webp" onerror="this.onerror=null;this.src="https://i.giphy.com/ujUdrdpX7Ok5W.gif" alt="">',
      //       // },
      //       {
      //         title: "Element",
      //         element: document.querySelector(".guide-detail"),
      //         intro: "This step focuses on an element",
      //       },
      //     ],
      //     highlightClass: "intro-no-border",
      //     showBullets: false,
      //     skipLabel: "",
      //     hidePrev: true,
      //   })
      //   .start();
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is not valid");
      }
      return errors;
    },
  },
  computed: {
    tableProperties: function() {
      return {
        title: "Complete Profile",
        description: "Please add profile details",
      };
    },
    licenseErrors: function() {
      return this.handleFormValidation("license");
    },
    nameErrors: function() {
      return this.handleFormValidation("name");
    },
    surnameErrors: function() {
      return this.handleFormValidation("surname");
    },
    contact_emailErrors: function() {
      return this.handleFormValidation("contact_email");
    },
    fincance_emailErrors: function() {
      return this.handleFormValidation("fincance_email");
    },
    // is_service_requiredErrors: function() {
    //   return this.handleFormValidation("is_service_required");
    // },
    regionsErrors: function() {
      return this.handleFormValidation("regions");
    },
  },
};
</script>

<!--<style scoped>-->
<!--.custom-content-height-form {-->
<!--  overflow-y: scroll;-->
<!--  position: relative;-->
<!--  height: calc(100vh - 390px) !important;-->
<!--}-->
<!--.custom-content-height-form::-webkit-scrollbar {-->
<!--  width: 1px !important;-->
<!--}-->
<!--.custom-content-height-form::-webkit-scrollbar-thumb {-->
<!--  border-radius: 12px !important;-->
<!--}-->

<!--@media (max-width: 1199.98px) {-->
<!--  .custom-content-height-form {-->
<!--    height: calc(100vh - 360px) !important;-->
<!--  }-->
<!--}-->

<!--@media (max-width: 991.98px) {-->
<!--  .custom-content-height-form {-->
<!--    height: calc(100vh - 348px) !important;-->
<!--  }-->
<!--}-->
<!--</style>-->
